<!-- 大会议程 -->
<template>
  <div class="conferenceAgendaPageContainer">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="
                require('../../assets/images/conferenceAgenda/confer-ch.png')
              "
              :fit="'fill'"
            >
            </el-image>
            <div class="title" :class="{ weibei: lang }">
              {{ $t("programme") }}
            </div>
          </div>
          <div class="header h5">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("programme") }}
            </div>
          </div>
          <div class="content lcontainer">
            <!-- <el-image style="width: 100%;" :src="conferTable" :fit="'fill'">
                        </el-image> -->
            <div class="con-table">
              <div class="title">{{ $t("secondGlobalBamboo") }}</div>
              <div class="click">{{ $t("click") }}</div>
              <div class="table-wrap">
                <div class="stitle">{{ $t("preliminaryProgramme") }}</div>
                <table border="1" cellspacing="0" class="conTable">
                  <tbody>
                    <tr
                      style="
                        height: 60px;
                        font-weight: 700;
                        color: #fff;
                        background: #8dc63f;
                      "
                    >
                      <td valign="center" width="30%" class="pc">
                        <p align="center">
                          <span>{{ $t("conferenceAgendaTable.h1") }}</span>
                        </p>
                      </td>
                      <td valign="center" width="17%" class="h5">
                        <p align="center">
                          <span>{{ $t("conferenceAgendaTable.h1") }}</span>
                        </p>
                      </td>
                      <td valign="center" width="30%" class="pc">
                        <p align="center">
                          <span>{{ $t("conferenceAgendaTable.h2") }}</span>
                        </p>
                      </td>
                      <td valign="center" width="20%" class="h5">
                        <p align="center">
                          <span>{{ $t("conferenceAgendaTable.h2") }}</span>
                        </p>
                      </td>
                      <!-- <td valign="center" width="18%">
                                                <p align="center">
                                                    <span>{{
                                                            $t("conferenceAgendaTable.h3")
                                                    }}</span>
                                                </p>
                                            </td> -->
                      <td valign="center">
                        <p align="center">
                          <span>{{ $t("conferenceAgendaTable.h4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td valign="center">
                        <p align="center">
                          <span>
                            {{ $t("conferenceAgendaTable.day1.val") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span>
                            {{ $t("conferenceAgendaTable.day1.time") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" style="height: 80px">
                        <div>
                          <div>{{ $t("conferenceAgendaTable.day1.cont") }}</div>
                        </div>
                      </td>
                    </tr> -->
                    <tr v-for="(item, index) in $t('cA')" :key="index">
                      <td valign="center">
                        <p align="center">
                          <span> {{ item.date }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p
                          v-for="(items, indexs) in item.time"
                          :key="indexs"
                          align="center"
                          style="height: 80px"
                          :class="indexs == 2 ? 'gao' : ''"
                          :style="
                            indexs == item.time.length - 1
                              ? ''
                              : 'border-bottom: 1px solid #d5e2d1'
                          "
                          class="flex a-c j-c"
                        >
                          <span>{{ items }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p
                          v-for="(itemss, indexss) in item.title"
                          :key="indexss"
                          align="center"
                          class="flex a-c j-c"
                          style="height: 80px; flex-wrap: wrap"
                          :class="indexss == 2 ? 'gao' : ''"
                          :style="
                            indexss == item.title.length - 1
                              ? ''
                              : 'border-bottom: 1px solid #d5e2d1'
                          "
                          @click="open(index, indexss)"
                        >
                          <span
                            v-html="itemss"
                            style="width: 100%"
                            :class="index == 1&&indexss==0 ? '' : 'a-color'"
                          >
                          </span>
                          <i
                            v-if="indexss == 0 && index == 0"
                            style="font-size: 14px"
                          >
                            {{
                              lang
                                ? "视频将在会后适时上线播放"
                                : "Video will be played in due time after the Congress"
                            }}
                          </i>
                          <span>
                            <div
                              v-if="indexss == 2 && lang"
                              class="tybg"
                              style="line-height: 1.6"
                            >
                              <p class="bold">
                                <span
                                  >题<span style="opacity: 0">讲嘉</span
                                  >目：</span
                                >
                                <span class="con"
                                  >落实全球发展倡议，推进以竹代塑行动</span
                                >
                              </p>
                              <p>
                                <span class="bold">演讲嘉宾：</span>
                                <span class="con"
                                  >国际竹藤组织董事会联合主席、国际竹藤中心首席科学家、国际木材科学院院士江泽慧教授</span
                                >
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                <span
                                  >题<span style="opacity: 0">讲嘉</span
                                  >目：</span
                                >
                                <span class="con"
                                  >生态系统恢复全球概况：联合国2021-2030十年倡议下的机遇与挑战(线上)</span
                                >
                              </p>
                              <p>
                                <span class="bold">演讲嘉宾：</span>
                                <span class="con"
                                  >联合国粮农组织高级林业官员、森林和景观恢复机制(FLRM)协调员克里斯托夫·贝萨西耶</span
                                >
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                <span
                                  >题<span style="opacity: 0">讲嘉</span
                                  >目：</span
                                >
                                <span class="con">碳中和与绿色高质量发展</span>
                              </p>
                              <p>
                                <span class="bold">演讲嘉宾：</span>
                                <span class="con"
                                  >中国工程院院士、北京林业大学原校长尹伟伦教授</span
                                >
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                <span
                                  >题<span style="opacity: 0">讲嘉</span
                                  >目：</span
                                >
                                <span class="con"
                                  >碳中和目标下的工程竹材的可持续性和循环性(线上)</span
                                >
                              </p>
                              <p>
                                <span class="bold">演讲嘉宾：</span>
                                <span class="con"
                                  >荷兰代尔夫特理工大学巴勃罗·范德卢特博士</span
                                >
                              </p>
                            </div>
                            <div v-if="indexss == 2 && !lang" class="tybg">
                              <p class="bold">
                                Implementing the global development initiative
                                and promoting the action of substituting bamboo
                                for plastics
                              </p>
                              <p>
                                <span class="bold">Speaker:</span> Prof. Jiang
                                Zehui, Co-Chair of INBAR Board of Trustees,
                                Chief Scientist of International Centre for
                                Bamboo and Rattan, Academician of International
                                Academy of Wood Science
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                Global overview for ecosystem restoration:
                                opportunities and challenges in the context of
                                the United Nations Decade 2021-2030 (Virtual
                                Presentation)
                              </p>
                              <p>
                                <span class="bold">Speaker:</span> Christophe
                                Besacier, Senior Forestry Officer, Coordinator
                                of the Forest and Landscape Restoration
                                Mechanism, FAO, Italy
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                Green and high quality development in the
                                context of carbon neutrality
                              </p>
                              <p>
                                <span class="bold">Speaker:</span> Prof. Yin
                                Weilun, Academician of Chinese Academy of
                                Engineering, Former President of Beijing
                                Forestry University, China
                              </p>
                              <p style="margin-top: 10px" class="bold">
                                Sustainability and circularity of engineered
                                bamboo for carbon neutrality (Virtual
                                Presentation)
                              </p>
                              <p>
                                <span class="bold">Speaker:</span> Dr. Pablo van
                                der Lugt, Delft University of Technology,
                                Netherlands
                              </p>
                            </div>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="1" class="displays">
                  <div class="left item">
                    <img
                      src="../../assets/images/conferenceAgenda/2.png"
                      class="labImg"
                      alt=""
                    />
                    <div class="btnBox" @click="openType(1)">
                      <img
                        src="../../assets/images/xz.png"
                        class="xdIcon"
                        alt=""
                      />
                      点击下载
                    </div>
                  </div>
                  <div class="right item">
                    <img
                      src="../../assets/images/conferenceAgenda/1.png"
                      class="labImg"
                      alt=""
                    />
                    <div class="btnBox" @click="openType(2)">
                      <img
                        src="../../assets/images/xz.png"
                        class="xdIcon"
                        alt=""
                      />
                      Download
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      conferTable: "conferTable",
    };
  },
  created() {
    this.innit();
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    openType(i) {
      if (i == 1) {
        window.open("https://www.barc2022.inbar.int/barcfile/shouce1.pdf");
      } else {
        window.open("https://www.barc2022.inbar.int/barcfile/shouce2.pdf");
      }
    },
    open(i, t) {
      if (i == 1) {
        if (t == 0) {
          window.open("https://www.barc2022.inbar.int/#/parallelMeeting");
        }
        if (t == 1) {
          window.open(
            "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=63687ea9357c7c77532c458a"
          );
        }
      }
      if (i == 0 && t != 0) {
        window.open(
          "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=627cab8a54e52545926dfe6a"
        );
        // if (t == 0) {
        //   window.open(
        //     "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=627cab8a54e52545926dfe6a"
        //   );
        // }
        // if (t == 1 || t == 2) {
        //   window.open(
        //     "https://barc2022.chinafuturelink.com/#/meeting/live/detail?live_id=627cabb5721eb15cda15152a"
        //   );
        // }
      }
    },
    innit() {
      if (window.localStorage.getItem("locale") == "zh-CN") {
        this.conferTable = require("../../assets/images/conferenceAgenda/confer-table.png");
      } else {
        this.conferTable = require("../../assets/images/conferenceAgenda/confer-table-en.png");
        console.log(window.localStorage.getItem("locale"));
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .conferenceAgendaPageContainer {
    width: 100%;
    overflow: hidden;

    table, td, th {
      border: 0.5px solid #d5e2d1;
      border-top: none;
    }

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 42px;
        }
      }

      .content {
        margin-top: 69px;
        margin-bottom: 109px;

        .con-table {
          min-height: 1000px;
          background: url('../../assets/images/conferenceAgenda/tbg.png') 100% 100%;
          padding: 50px 40px;
          box-sizing: border-box;
          background-size: cover;

          .title {
            font-size: 42px;
            font-weight: bold;
            color: #405E3C;
            margin-bottom: 20px;
          }

          .click {
            margin-bottom: 20px;
            color: #405e3c;
            font-size: 19px;
          }

          .table-wrap {
            .stitle {
              font-size: 32px;
              height: 65px;
              line-height: 65px;
              color: #fff;
              background: #405E3C;
              margin: 0 1px;
              border-left: 1px solid #d5e2d1;
              border-right: 1px solid #d5e2d1;
            }

            .conTable {
              width: 100%;

              tr {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }

  .gao {
    height: 400px !important;
    flex-wrap: wrap;
    align-content: center;
  }

  .tybg {
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;

    p {
      display: flex;

      .con {
        flex: 1;
      }
    }
  }

  .btnBox {
    width: 230px;
    height: 50px;
    margin: auto;
    background: #8dc32b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;

    .xdIcon {
      width: 28px;
      margin-right: 10px;
    }
  }

  .displays {
    display: flex;
    margin-top: 20px;

    .item {
      flex: 1;
    }
  }

  .labImg {
    width: 40%;
    border: 2px solid #eeeeee;
  }
}

@media screen and (max-width: 728px) {
  .displays {
    margin-top: 10px;

    .item {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .labImg {
    width: 100%;
    border: 4px solid #eeeeee;
    box-sizing: border-box;
  }

  .btnBox {
    width: 140px;
    height: 40px;
    margin: auto;
    background: #8dc32b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;

    .xdIcon {
      width: 18px;
      margin-right: 6px;
    }
  }

  .conferenceAgendaPageContainer {
    table, td, th {
      border: 0.5px solid #d5e2d1;
      border-top: none;
    }

    width: 100%;
    overflow: hidden;

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 200px;
        background: url('../../assets/images/conferenceAgenda/banner-h5.png');
        background-size: 100% 100%;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          width: 164px;
          height: 60px;
          line-height: 60px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 22px;
        }
      }

      .content {
        margin-top: 40px;
        margin-bottom: 40px;

        .con-table {
          background: red;
          background: url('../../assets/images/conferenceAgenda/tbg.png') 100% 100%;
          padding: 30px 20px;
          background-size: cover;
          box-sizing: border-box;

          td {
            font-size: 14px;
          }

          .title {
            font-size: 24px;
            font-weight: bold;
            color: #405E3C;
            margin-bottom: 10px;
          }

          .click {
            margin-bottom: 10px;
            color: #405e3c;
            font-size: 12px;
          }

          .table-wrap {
            .stitle {
              font-size: 22px;
              height: 65px;
              line-height: 65px;
              color: #fff;
              background: #405E3C;
              margin: 0 1px;
              border-left: 1px solid #d5e2d1;
              border-right: 1px solid #d5e2d1;
            }

            .conTable {
              width: 100%;

              tr {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }

  .gao {
    height: 500px !important;
    flex-wrap: wrap;
    align-content: center;
  }

  .tybg {
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
  }
}

p {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
</style>